@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #F4976C;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  position: relative;
}